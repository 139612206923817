import { render, staticRenderFns } from "./ProductExtAttrItem.vue?vue&type=template&id=288c4d19&scoped=true&"
import script from "./ProductExtAttrItem.vue?vue&type=script&lang=js&"
export * from "./ProductExtAttrItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288c4d19",
  null
  
)

component.options.__file = "ProductExtAttrItem.vue"
export default component.exports